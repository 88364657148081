import {
  PrismicDocument,
  PrismicItem,
  PrismicSlice,
  PrismicSliceType
} from "./PrismicModels"

export class PrismicProductCodeAggregator {
  public aggregateMany(documents: PrismicDocument[]): string[] {
    return documents.flatMap(it => this.aggregate(it))
  }

  public aggregate(document: PrismicDocument): string[] {
    const extract = this.extractProductCode

    return (document.data?.body || [])
      .filter(
        it =>
          it.slice_type === PrismicSliceType.productRecommendationIds ||
          it.slice_type === PrismicSliceType.navigationBar ||
          it.slice_type === PrismicSliceType.productListCaption ||
          it.slice_type === PrismicSliceType.statementHero ||
          it.slice_type === PrismicSliceType.titleSentenceHero ||
          it.slice_type === PrismicSliceType.banner ||
          it.slice_type === PrismicSliceType.promotionCards ||
          it.slice_type === PrismicSliceType.card ||
          it.slice_type === PrismicSliceType.product_and_asset
      )
      .flatMap(slice => slice.items)
      .flatMap(extract)
  }

  public extractProductCode(item: PrismicItem): string[] {
    const result: string[] = []
    if (item.product_id) {
      result.push(item.product_id.productCode)
    }
    if (item.product_id_2) {
      result.push(item.product_id_2.productCode)
    }
    if (item.product_id_3) {
      result.push(item.product_id_3.productCode)
    }
    if (item.product_id_4) {
      result.push(item.product_id_4.productCode)
    }
    return result
  }
}

export interface ProductAttribute {
  name: string
  value: string
}

export interface PrismicProductCategory {
  id: string
  key: string
  filter: string
  limit: number
}

export class PrismicProductByCategoryAggregator {
  aggregateMany(documents: PrismicDocument[]): PrismicProductCategory[] {
    return documents.flatMap(it => this.aggregate(it))
  }

  private aggregate(document: PrismicDocument): PrismicProductCategory[] {
    const extract = this.extractCategorySlug

    return (document.data?.body || [])
      .filter(it => it.slice_type === PrismicSliceType.productsByCategory)
      .flatMap(extract)
  }

  private extractCategorySlug(slice: PrismicSlice): PrismicProductCategory[] {
    const { dynamic_category_modification: filter = "", limit } = slice.primary
    return slice.items
      .filter(it => it.commerce_tools_category)
      .map(it => {
        const { id, key } = it.commerce_tools_category || {}
        return {
          id: id || "",
          key: key || "",
          filter,
          limit: limit || 5
        }
      })
  }
}

export class PrismicProductAttributeAggregator {
  public aggregateMany(documents: PrismicDocument[]): ProductAttribute[] {
    return documents.flatMap(it => this.aggregate(it))
  }

  public aggregate(document: PrismicDocument): ProductAttribute[] {
    return (document.data?.body || [])
      .filter(this.isProductRecommendationByAttribute)
      .flatMap(this.extractProductAttributes)
  }

  public isProductRecommendationByAttribute = (item: PrismicSlice): boolean => {
    return item.slice_type === PrismicSliceType.productRecommendationByAttribute
  }

  public extractProductAttributes = (
    item: PrismicSlice
  ): ProductAttribute[] => {
    if (
      item.primary.product_recommendation_attribute_key &&
      item.primary.product_recommendation_attribute_value
    ) {
      return [
        {
          name: item.primary.product_recommendation_attribute_key,
          value: item.primary.product_recommendation_attribute_value
        }
      ]
    } else {
      return []
    }
  }
}
